/* stylelint-disable */

@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import 'scss/colors.scss';

.rbc-event {
  background-color: $white !important;
  border-radius: 0 !important;
  border-top: 6px solid $orange !important;
  box-shadow: 0 2px 4px $silver !important;
  margin-bottom: 10px !important;
  padding: 0 !important;
  padding-left: 6px !important;
}

.rbc-btn-group {
  order: 2 !important;
}

.rbc-header {
  border: none !important;
  text-align: initial !important;

  span {
    color: $gray;
    font-size: $xsmall;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
    text-transform: uppercase;
  }
}

.rbc-month-view {
  border: none !important;
}

.rbc-off-range-bg {
  background-color: $alabaster-opacity;
}


.rbc-row-bg:nth-child(1) .rbc-day-bg {
  border-top: none;
}

.rbc-month-row:last-child .rbc-off-range-bg,
.rbc-month-row:last-child .rbc-day-bg {
  border-bottom: 0.5px solid $silver;
}

.rbc-month-row {
  border-left: 0.5px solid $silver;
}

.rbc-row-bg:nth-child(1) .rbc-day-bg:last-child {
  border-right: 0.5px solid $silver;
}

.rbc-row-bg:last-child .rbc-day-bg {
  border-bottom: 0.5px solid $silver;
}

.rbc-month-row + .rbc-month-row {
  border-left: 0.5px solid $silver;
}

.rbc-date-cell {
  color: $gray;
  font-size: $xsmall;
}

.rbc-today {
  background-color: $orange-opacity !important;
}

@media only screen and (max-width: $medium-size-query) {
  .rbc-header {
    display: flex;
    justify-content: center;

    span {
      letter-spacing: 10px;
      overflow: hidden;
      width: 15px;
    }
  }

  .rbc-month-view {
    z-index: 0;
  }
}
