$xxsmall: 12px;
$xsmall: 14px;
$small: 16px;
$demi-medium: 18px;
$medium: 20px;
$large: 24px;
$big: 32px;
$xbig: 36px;
$xxbig: 38px;
$xxxbig: 44px;
$xxxxbig: 224px;
