@import '~wolox-equalizer/equalizer';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'layout';
@import 'margins';
@import 'components';
@import 'buttons';
@import 'overrides/circle-progress';
@import 'texts';
@import 'elementsUI';
@import 'overrides/slider-override.scss';
@import 'overrides/calendar.scss';
@import 'overrides/scrollbar.scss';
@import 'sizes.scss';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

// TODO: Edit this global styles

body {
  font-family: -apple-system, Roboto, BlinkMacSystemFont, Oxygen, Ubuntu, 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  color: $dark-blue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.slideButton {
  display: inline-block;
  color: white;
  background-color: $orange;
  font-weight: 700;
  padding: 12px 50px;
  border-radius: 99em;
  border: none;
  text-align: center;
  margin: 20px 0;
}

.moreButton {
  display: inline-block;
  color: white;
  background-color: transparent;
  font-weight: 700;
  padding: 12px 50px;
  border-radius: 99em;
  border: 0.5px solid $white;
  text-align: center;
  margin: 20px 0;
}

.moreButton:hover {
  background-color: $orange;
}

.react-toggle {
  margin-bottom: -5px;
  margin-left: 5px;
}

.react-toggle .react-toggle-track {
  background-color: $gray !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: $orange !important;
}

.react-toggle--checked .react-toggle-thumb {
  border-color: $gray !important;
}

.image-gallery-icon:hover {
  color: #FB552E !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 4px solid #FB552E !important;
}

strong {
  font-weight: 900;
}
