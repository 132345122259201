@import 'scss/sizes.scss';

.container {
  margin: 0 auto;
  max-width: $main-container-width;
  min-height: calc(100vh - #{$footer-height} - #{$header-height});
  padding: 0 15px;
  margin-bottom: 20px;
  margin-top: 70px;
}

.projectsConteiner {
  padding-left: 15px;
  padding-right: 0;
  max-width: 100vw;
  margin-bottom: 0 !important;
}

.main {
  max-height: 100vh;
}

@media only screen and (max-width: $main-container-width) {
  .main {
    max-height: none;
  }
}
@media only screen and (max-width: $navbar-break-point) {
  .container {
    padding-top: 30px;
    margin-top: 0;
  }

  .projectsConteiner {
    padding-left: 0;
  }

}
