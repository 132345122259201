@import 'colors.scss';

.primary-button {
  background-color: $black;
  border-radius: 10px;
  color: $white;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 600;
}
