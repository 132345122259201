@import 'colors';
@import 'fontSizes';
@import 'sizes';

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-word;
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title-big {
  color: $dark-blue;
  font-size: $xxbig;
  font-weight: 500;
  line-height: 40px;
}

.title-xbig {
  color: $dark-blue;
  font-size: $xbig;
  font-weight: 600;
  line-height: 38px;
}

.title-xxxbig {
  color: $dark-blue;
  font-size: $xxxbig;
  font-weight: 600;
  line-height: 52px;
}

.title-medium {
  color: $dark-blue;
  font-size: $demi-medium;
  font-weight: 700;
  line-height: 20px;
}

.subtitle,
.caption {
  color: $dark-blue;
  font-size: $demi-medium;
  font-weight: 500;
  line-height: 24px;
}

.highlight {
  color: $dark-blue;
  font-size: $large;
  font-weight: 700;
  line-height: 32px;
}

.text-medium,
.text-event {
  color: $dark-blue;
  font-size: $demi-medium;
  font-weight: 500;
  line-height: 28px;

  a {
    color: #FB552E !important;
    font-size: $demi-medium;
  }

}

.text-medium-orange {
  color: $orange;
  font-size: $demi-medium;
  font-weight: 500;
  line-height: 20px;
}

.text-medium-subtitle {
  color: $blue-gray;
  font-size: $demi-medium;
  font-weight: 500;
  line-height: 20px;
}

.rich-text p {
  display: block;
  color: #023B4D;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.rich-text li {
  list-style-type: circle;
  margin-left: 20px;
}

.text-xsmall {
  color: $mine-shaft;
  font-size: $xsmall;
  font-weight: 500;
  line-height: 20px;
}

.text-xsmall-orange {
  color: $orange;
  font-size: $xsmall;
  font-weight: 500;
  line-height: 20px;
}

.text-xsmall-subtitle {
  color: $gray;
  font-size: $xsmall;
  font-weight: 500;
  line-height: 20px;
}

.text-large-subtitle {
  color: $blue-gray;
  font-size: $large;
  font-weight: 500;
  line-height: 20px;
}

.text-xxsmall {
  color: $mine-shaft;
  font-size: $xxsmall;
  line-height: 14px;
}

.text-small {
  color: $dark-blue;
  font-size: $small;
  font-weight: 500;
  line-height: 20px;
}

.text-large {
  color: $mine-shaft;
  font-size: $large;
  font-weight: 700;
  line-height: $big;
}

.paratext {
  color: $blue-gray;
  font-size: $small;
  font-weight: 500;
  line-height: 20px;
}

.text-caption {
  color: $blue-gray;
  font-size: $xsmall;
  font-weight: 500;
  line-height: 16px;
}

.button {
  color: $white;
  font-size: $small;
  font-weight: 500;
  line-height: 16px;
}

.link-copy {
  border-bottom: 1px solid $orange;
  color: $orange;
  font-size: $small;
  font-weight: 500;
  height: 21px;
  line-height: 20px;
}

.content-subtitle {
  color: $mine-shaft;
  font-size: $demi-medium;
  font-weight: 600;
  line-height: 28px;
}

.medium {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.normal-bold {
  font-weight: 300;
}

.line-separator {
  position: relative;

  &::after {
    content: ' – ';
  }
}

.line-separator-b {
  position: relative;

  &::before {
    content: ' – ';
  }
}

.line-item {
  &::before {
    content: ' – ';
  }
}

.capitalize {
  text-transform: capitalize;
}

@media only screen and (max-width: $medium-size-query) {
  .subtitle {
    font-size: $demi-medium;
    line-height: $demi-medium;
  }

  .text-caption {
    color: $blue-gray;
    font-size: $xxsmall;
    font-weight: 500;
    line-height: 17px;
  }

  .caption {
    font-size: $small;
    line-height: 22px;
  }

  .text-medium {
    font-size: $small;
    line-height: $large;
  }

  .title-medium {
    font-size: $demi-medium;
    line-height: $large;
  }

  .title-big {
    font-size: 26px;
    line-height: $xbig;
  }

  .title-xbig {
    font-size: $demi-medium;
  }

  .highlight {
    font-size: $small;
  }

  .title-xxxbig {
    font-size: $large;
    line-height: 32px;
  }

  .text-medium-subtitle {
    font-size: $small;
    line-height: 22px;
  }

  .text-small,
  .paratext {
    font-size: $small;
  }

  .text-xsmall {
    font-size: $xxsmall;
  }

  .text-xsmall-subtitle {
    font-size: $xxsmall;
  }

  .text-event {
    font-size: $xsmall;
  }
}
