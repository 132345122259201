@import 'colors';
$border-silver: 0.5px solid $silver;
$radius-buttons: 10px;

.buttons {
  background-color: $black;
  border-radius: $radius-buttons;
  height: 45px;
  min-width: 126px;

  &.hover {
    background-color: $mine-shaft;
  }

  &.inactive {
    background-color: $silver;
    color: $gray;
  }
}

.buttons-secondary {
  background-color: $white;
  border: $border-silver;
  border-radius: $radius-buttons;
  color: $mine-shaft;
  font-size: $small;
  font-weight: 700;
  height: 45px;
  min-width: 126px;
  padding: 15px 20px;

  span {
    color: $mine-shaft;
    font-size: $small;
    font-weight: 600;
  }

  &.hover {
    border: 0.5px solid $black;
  }

  &.inactive {
    background-color: $silver;
    color: $gray;
  }
}

.buttons-text {
  color: $mine-shaft;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin: 15px 20px;
  text-align: center;
}

.link {
  border-bottom: 1px solid $orange;
  color: $orange;
  font-size: $small;
  font-weight: 600;
  line-height: 16px;

  &.inactive {
    border-bottom: 1px solid $silver;
    cursor: default;
    pointer-events: none;
  }
}

@media only screen and (max-width: $medium-size-query) {
  .link {
    font-size: $demi-medium;
  }
}

.dropdown {
  background-color: $white;
  border: $border-silver;
  border-radius: $radius-buttons;
  min-width: 200px;
  position: absolute;
  z-index: 10;

  &.dropdown-left {
    left: 0;
    top: 30px;
  }

  &.dropdown-right {
    right: 0;
    top: 30px;
  }

  &.dropdown-top {
    bottom: 100%;
    top: auto;
  }
}

.dropdown-items {
  border-bottom: 1px solid $silver;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
}

.is-hoverable {
  cursor: pointer;
}

%props-arrows {
  background-repeat: no-repeat;
  height: 22px;
  vertical-align: middle;
}

$padding-width: 20px;
$filter: grayscale(100%);

.right-arrow {

  @extend %props-arrows;
  background-image: url('../assets/ic_general_arrow_right_active.svg');
  background-position: right;
  padding-right: $padding-width;

  &:disabled {
    background-image: url('../assets/ic_general_arrow_right_disable.svg');
  }
}

.right-arrow.gray {

  @extend %props-arrows;
  background-image: url('../assets/ic_general_arrow_right_active_gray.svg');
  background-position: right;
  padding-right: $padding-width;

  &:disabled {
    background-image: url('../assets/ic_general_arrow_right_disable_gray.svg');
  }
}

.right-arrow.blue {

  @extend %props-arrows;
  background-image: url('../assets/ic_general_arrow_right_active_blue.svg');
  background-position: right;
  padding-right: $padding-width;

  &:disabled {
    background-image: url('../assets/ic_general_arrow_right_disable.svg');
  }
}

.left-arrow {

  @extend %props-arrows;
  background-image: url('../assets/ic_general_arrow_left_active.svg');
  background-position: left;
  padding-left: $padding-width;

  &:disabled {
    background-image: url('../assets/ic_general_arrow_left_disable.svg');
  }
}

.left-arrow.gray {

  @extend %props-arrows;
  background-image: url('../assets/ic_general_arrow_left_active_gray.svg');
  background-position: left;
  padding-left: $padding-width;

  &:disabled {
    background-image: url('../assets/ic_general_arrow_left_disable.svg');
  }
}

.left-arrow.blue {

  @extend %props-arrows;
  background-image: url('../assets/ic_general_arrow_left_active_blue.svg');
  background-position: left;
  padding-left: $padding-width;

  &:disabled {
    background-image: url('../assets/ic_general_arrow_left_disable.svg');
  }
}

.disabled {
  filter: $filter;
  pointer-events: none;
}

.checkbox {
  cursor: pointer;
}

.mine-shaft-color {
  color: $mine-shaft;
}

.no-padding {
  padding: 0;
}

.outline {
  outline: 0;
}
