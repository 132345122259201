* {
  font-family: Archivo, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Archivo;
  font-weight: 700;
  src: url('~scss/fonts/Archivo-Bold.ttf') format('truetype');
}
@font-face {
  font-family: Archivo;
  font-weight: 600;
  src: url('~scss/fonts/Archivo-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: Archivo;
  font-weight: 500;
  src: url('~scss/fonts/Archivo-Medium.ttf') format('truetype');
}
@font-face {
  font-family: Archivo;
  src: url('~scss/fonts/Archivo-Regular.ttf') format('truetype');
}
