$black: #000;
$white: #FFF;
$gray: #D0D9DE;
$blue-gray: #7B97AD;
$blue: #4D738A;
$dark-blue: #023B4D;
$dark-blue-opacity: #023B4D30;
$orange: #FB552E;
$orange-opacity: #FB552E47;
$silver: #BEBEBE;
$mine-shaft: #3E3E3E;
$mine-shaft-two: #353535;
$wild-sand: #F0F0F0;
$dark-shadow: rgba(155, 155, 155, 0.3);
$transparent: transparent;
$mountain-meadow: #18BC5C;
$mountain-meadow-two: #28DE73;
$tara: #E2F6EA;
$alabaster: #F9F9F9;
$alabaster-opacity: rgba(216, 216, 216, 0.2);
$text-shadow: rgba(0, 0, 0, 0.5);
$background-video: rgba(0, 0, 0, 0.3);
