$header-height: 90px;
$footer-height: 101px;
$footer-width: 532px;
$logo-height: 34px;
$progress-width: 130px;
$logo-width: 135px;
$logo-bid-height: 30px;
$logo-intal-height: 22px;
$logo-caf-height: 27px;
$logo-fcn-height: 30px;
$invisible-container-width: 1040px;
$mobile-header-height: 65px;
$min-desktop-width: 1280px;
$min-landscape-width: 1024px;
$logo-sendera-height: 57px;
$main-container-width: 1180px;
$menu-width: 450px;
$transition-duration: 290ms;
$transition-function: cubic-bezier(0.79, 0.01, 0.38, 0.99);
$medium-size-query: 900px;
$small-size-query: 600px;
$micro-size-query: 365px;
$navbar-break-point: 820px;
$z-index-navbar: 4;
$slider-image-mobile-height: 140px;
$slider-image-mobile-width: 260px;
$card-image-height: 168.75px;
$card-image-width: 300px;
$grid-card-image-width: 370px;
$grid-card-image-height: 208px;
$card-image-height-small: 112.5px;
$card-image-width-small: 260px;
$filter-stack: 1085px;
