/* stylelint-disable */

@import '../colors.scss';

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  background-color: $alabaster;
  opacity: 1;
  width: 10px;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.8;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__thumb-y {
  background-color: $gray;
  width: 6px;
}
