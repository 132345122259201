/* stylelint-disable */

@import 'scss/colors.scss';

.input-range__track--active {
  background: $orange !important;
}

.input-range__slider {
  background: $white !important;
  border: 5px solid $orange !important;
}

.input-range__label--min {
  display: none;
}

.input-range__label--max {
  display: none;
}

.input-range__label--value {
  top: 1rem !important;
}

.input-range__label-container {
  color: $white !important;
}

@media only screen and (max-width: $small-size-query) {
  .input-range {
    width: 85% !important;
  }
}
/* stylelint-enable */
