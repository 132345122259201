@import 'scss/colors.scss';
@import 'scss/sizes.scss';
@import 'scss/typography.scss';
@import 'scss/fontSizes.scss';
@import 'scss/margins.scss';

.footer {
  display: flex;
  //height: $footer-height;
  justify-content: center;
  background-color: $dark-blue;
  color: $blue-gray;

  a {
    color: white;
    font-size: $small;
  }
}

.linksContainer {
  :global(.row) {
    margin: 20px;
  }

  :global(.col) {
    margin: 0 40px;
  }
}

.copyright {
  font-size: $xxsmall;
}

.contacto {
  font-size: $xxsmall;
  color: $blue-gray;
}

.bid {
  height: $logo-bid-height;
}

.intal {
  height: $logo-intal-height;
}

.caf {
  height: $logo-caf-height;
}

.fcn {
  height: $logo-fcn-height;
  margin-bottom: 10px;
}

.logo-container {
  margin: 40px 0;

  img {
    min-height: 24px;
    height: 36px;
    width: auto;
  }
}

.mobile {
  display: none !important;
}

.imgCont{
  position: relative;
  margin-left: 20px;
}

.logoBanco {
  height: auto;
  max-height: 40px;
  display: inline-block;
  width: 125px;
}

@media only screen and (max-width: 530px) {
  .logoBanco {
    width: 22vw;
  }
}
@media only screen and (max-width: $navbar-break-point) {
  .logo-container {
    width: 25%;
    padding-left: 20px !important;

    img {
      height: auto;
      width: 100px;
      margin-top: 35px;
    }
  }

  .bid {
    width: 60px;
  }

  .intal {
    width: 55px;
  }

  .caf {
    width: 30px;
  }

  .fcn {
    width: 105px;
    margin-bottom: 8px;
  }

  .fRow {
    display: block !important;
    margin-left: 0 !important;
  }

  .fCol {
    margin-left: 0 !important;
  }

  .mobile {
    display: block !important;
  }

  .desktop {
    display: none !important;
  }

  .footer {
    height: calc(100vh - 93px);
  }

  .rights {
    padding-left: 20px;
  }

  .mb {
    margin-bottom: 55px !important;
  }

  .imgCont {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .logoBanco {
    margin-left: 15px;
  }

  .negMarginLeft {
    margin-left: -25px;
  }

}

.socialLogoContainer {
  border: solid 1px #4D7387;
  border-radius: 30px;
  margin-left: 15px;
}

.socialLogo {
  width: 40px;
}
