/* stylelint-disable */

@import '../colors.scss';

.CircularProgressbar {
  justify-content: center;
  vertical-align: middle;
  width: 100%;

  .CircularProgressbar-path {
    stroke: $mountain-meadow;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar-trail {
    stroke: $silver;
    stroke-width: 1px;
  }

}

.original-investment {
  width: 256px;

  .CircularProgressbar {
    .CircularProgressbar-path {
      stroke: $mine-shaft;
    }
  }
}
/* stylelint-enable */
